<template>
    <div class="home max-w-screen-mobile mx-auto h-screen  py-2 text-center realtive">
        <img class="w-32 h-32 mx-auto mt-6 mb-6" alt="Szatmár Megyei Múzeum" v-bind:src="getLogo()" />
        <p class="text-sepiaBlack dark:text-white">{{ $t('400pages.notFoundText')}}</p>
        <router-link :to="{ name: 'MobileMenu' }" class="text-sepiaBlack dark:text-white">
            {{$t('400pages.notFoundRedirect') }}
        </router-link>
    </div>
</template>

<script>

export default {
    name: "NotFoundPage",
    mounted() {
		this.$i18n.locale = this.lang;
	},
    data() {
		return {
			lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
		}
	},
    methods: {
        getLogo: function() {
        const element = document.getElementById("app");
        if (element.classList.contains('darkMode')) {
            return require("../assets/logo_dark.png");
        } else {
            return require("../assets/logo_light.png");
        }
        },
    },
};
</script>
